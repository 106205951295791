import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters(["currentUser"]),
    getTDPBranch() {
      return true;
    },
  },
  methods: {
    async getCustomer() {
      const { data } = await this.$axios.get(
        `${process.env.VUE_APP_BASE_API_GARDTREX}/v1/cms/customers`,
        {
          params: {
            limit: 1000,
          },
        }
      );
      const listCustomer = [];
      listCustomer.push({ value: null, text: "Filter Site Group" });
      data.list.map((item) => {
        listCustomer.push({
          value: item.id,
          text: item.name,
        });
      });
      return listCustomer;
    },
  },
};
