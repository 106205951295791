"use strict";

export const formatDate = (date) => {
  const newDate = new Date(date);
  return newDate.toLocaleDateString("en-US", {
    day: "numeric",
    month: "long",
    year: "numeric",
  });
};

export const dateToISOString = (date) => {
  const newDate = new Date(date).toISOString().split("T");
  return newDate[0];
};

export const timeFormat = (date) => {
  if (!date) return "-";
  const newDate = new Date(date);
  let currentHours = newDate.getHours();
  let currentMinutes = newDate.getMinutes();
  let currentSeconds = newDate.getSeconds();
  currentHours = ("0" + currentHours).slice(-2);
  currentMinutes = ("0" + currentMinutes).slice(-2);
  currentSeconds = ("0" + currentSeconds).slice(-2);
  return `${currentHours}:${currentMinutes}:${currentSeconds}`;
};

export const sortChar = (text, long = 8) => {
  return text.slice(0, long).concat("...");
};
